<template>
  <div style="background: #fff;color: #000;height: 100%;">
    <div class="my-swipe">
      <van-swipe :autoplay="5000" indicator-color="white">
        <van-swipe-item v-if="list.coverImg1">
          <img class="xq_img" :src="list.coverImg1" />
        </van-swipe-item>
        <van-swipe-item v-if="list.coverImg2">
          <img class="xq_img" :src="list.coverImg2" />
        </van-swipe-item>
        <van-swipe-item v-if="list.coverImg3">
          <img class="xq_img" :src="list.coverImg3" />
        </van-swipe-item>
        <van-swipe-item v-if="list.coverImg4">
          <img class="xq_img" :src="list.coverImg4" />
        </van-swipe-item>
      </van-swipe>
      <van-icon @click="back" class="f_ioc" name="arrow-left" />
    </div>
    <div class="xqbeij">
      <img src="../../assets/img/xqbeijin.png" alt />
      <div class="jiage">
        <div class="jiage_A">
          <span class="jin">￥</span>
          <span>{{list.activityPrice}}</span>
        </div>
        <del class="jiage_b">
          <span class="jin">￥</span>
          <span>{{list.sellPrice}}</span>
        </del>
      </div>
      <div class="shijian">
        <!-- <div>距离结束仅剩</div>
          <div class="shi_b">
            <span class="jian">29</span>:
            <span class="jian">24</span>:
            <span class="jian">54</span>:
            <span class="jian">7</span>
        </div>-->
      </div>
    </div>
    <div class="xq_h1">
      <span class="xq_h1_a">{{list.partName}}</span>
      <span class="qianggou">限时抢购</span>
    </div>
    <div class="xq_h2">
      <div class="yixuan">已选</div>
      <div class="xuan_a">
        <span class="xin_c">车型</span>
        <span class="xin_b">{{chexin.carBrand}}-{{chexin.carModel}}</span>
      </div>
      <div class="xuan_a">
        <span class="xin_c left">数量</span>
        <span class="xin_b left">
          <van-stepper v-model="value" />
        </span>
      </div>
      <div class="xuan_a">
        <span class="xin_c">物流</span>
        <span class="xin_b">
          <!-- （8月30日） -->
          <span class="ciri">次日达</span>现货，预计明天可送达
        </span>
      </div>
      <div class="xuan_a" style="margin-top: 16px;">
        <span class="xin_c left" style="line-height: 13px;">门店</span>
        <span class="xin_b left">
          <img class="mendiantu" v-if="shangjia.doorPic" :src="shangjia.doorPic" alt />
          <img class="mendiantu" v-else src="../../assets/img/mendian.png" alt />
        </span>
        <div class="left dian_ha">
          <div class="dian_h1">{{shangjia.name}}</div>
          <div class="dian_h2">
            {{juli}}km
            <span>免运费</span>
          </div>
          <div class="dian_h3">{{shangjia.city}}{{shangjia.district}}{{shangjia.address}}</div>
        </div>
      </div>
    </div>
    <div class="canpin">产品说明</div>
    <van-row class="msgHtmlBox ql-snow ql-editor" v-html="list.productExplain"></van-row>
    <div style="height:55px;"></div>
    <van-goods-action>
      <van-goods-action-icon icon="phone-o" text="电话咨询">
        <a :href="'tel:'+shangjia.tel1">联系门店</a>
      </van-goods-action-icon>
      <van-goods-action-icon icon="service-o" text="在线客服" @click />
      <van-goods-action-icon icon="star-o" text="收藏" @click />
      <van-goods-action-button type="danger" text="立即购买" @click="onClickButton" />
    </van-goods-action>
  </div>
</template>

<script>
import userModel from "../../api/user";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  components: {},
  // mixins: [preventBack], //注入
  data() {
    return {
      chexin: JSON.parse(sessionStorage.getItem("chexin")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      jd: JSON.parse(localStorage.getItem("JD")),
      wd: JSON.parse(localStorage.getItem("WD")),
      list: {},
      active: "0",
      value: 1,
      Aswitch: 1,
      juli: 0,
      id: this.$route.query.id
    };
  },
  methods: {
    qiehuan(row) {
      this.active = row;
    },
    onClickButton() {
      var item = this.list;
      var goods = [
        {
          attrImg: item.coverImg1,
          disc: 10,
          discStatus: 0,
          goodsId: item.id,
          goodsType: 1,
          goodsNum: this.value,
          price: item.activityPrice ? item.activityPrice : item.sellPrice,
          title: item.partName,
          type: 0
        }
      ];
      var orderTotal = item.activityPrice
        ? item.activityPrice * this.value
        : item.sellPrice * this.value;
      var services = [];
      var xiadan = {
        services: services,
        goods: goods,
        type: 1,
        orderTotal: orderTotal,
        applyItem: "换轮胎"
      };
      sessionStorage.removeItem("xiadan");
      sessionStorage.setItem("xiadan", JSON.stringify(xiadan));
      this.$router.push({
        path: "place_order",
        query: {
          Aswitch: 1,
          xiche:'xiche'
        }
      });
    },
    onLoad() {
      const data = {
        id: this.id
      };

      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中..."
      });
      if (this.Aswitch == 1) {
        userModel
          .details(data)
          .then(e => {
            loading.clear();
            this.list = e.data;
          })
          .catch(() => loading.clear());
      } else {
        userModel
          .miaoshadetails(data)
          .then(e => {
            loading.clear();
            this.list = e.data;
          })
          .catch(() => loading.clear());
      }
    },
    // pushHistory() {
    //   //写入空白历史路径
    //   let state = {
    //     title: "title",
    //     url: "#"
    //   };
    //   window.history.pushState(state, state.title, state.url);
    // },

    // backziding() {
    //   this.pushHistory();
    //   window.addEventListener(
    //     "popstate",
    //     function(e) {
    //       location.href = "/list";
    //     },
    //     false
    //   );
    // },
    back() {
      this.$router.go(-1);
      // this.$router.push({
      //   path: "list"
      // });
    }
  },
  created() {
    this.Aswitch = this.$route.query.Aswitch;
    this.onLoad();
    console.log(this.$route.query);
    if (JSON.parse(localStorage.getItem("JD")) != null) {
      var radLat1 = (this.wd * Math.PI) / 180.0;
      var radLat2 = (this.shangjia.positionWd * Math.PI) / 180.0;
      var a = radLat1 - radLat2;
      var b =
        (this.jd * Math.PI) / 180.0 -
        (this.shangjia.positionJd * Math.PI) / 180.0;
      var s =
        2 *
        Math.asin(
          Math.sqrt(
            Math.pow(Math.sin(a / 2), 2) +
              Math.cos(radLat1) *
                Math.cos(radLat2) *
                Math.pow(Math.sin(b / 2), 2)
          )
        );
      s = s * 6378.137; // EARTH_RADIUS;
      s = Math.round(s * 10000) / 10000;
      this.juli = s.toFixed(2);
    }
  },
  mounted() {
    document.addEventListener("WeixinJSBridgeReady", function onBridgeReady() {
      WeixinJSBridge.call("showToolbar");
      // WeixinJSBridge.call("hideOptionMenu");
    });

    this.active = this.$route.query.status;
    // this.backziding();
    console.log(this.active);
  }
};
</script>

<style lang="less" scoped>
.ord_lis .order_list:nth-of-type(n + 2) {
  margin-top: 10px;
}
.my-swipe {
  position: relative;

  //   height: 362px;
}
.xq_img {
  width: 100%;
  height: 300px;
}
.van-swipe-item {
  display: flex;
}
.xqbeij {
  transform: translateZ(0);
  // position: absolute;

  // left: 0;
  width: 100%;
  //   height: 40px;
  // z-index: 99;
}
.f_ioc {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  margin-top: 10px;
  margin-left: 10px;
  width: 30px;
  height: 30px;
  color: #fff;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  background-color: rgba(31, 45, 61, 0.3);
  border-radius: 50%;
}
.canpin {
  padding: 0 15px;
  font-size: 16px;
  font-weight: bold;
  margin-top: 15px;
}
.jiage {
  font-size: 14px;
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  margin-left: 15px;
  margin-top: 12px;
  font-size-adjust: 0.58;
  font-family: Times;
}
.jin {
  font-size: 12px;
  color: #fff;
}
.jiage_A {
  font-size: 23px;
  font-weight: bold;
  line-height: 14px;
}
.shijian {
  font-size: 12px;
  color: #fff;
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 15px;
  margin-top: 8px;
  text-align: center;
}
.xq_h1 {
  background: #fff;
  overflow: hidden;
  padding: 12px 15px 20px 15px;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  border-bottom: 8px solid #f5f5f5;
}
.xq_h2 {
  padding: 10px 15px 15px 15px;
  border-bottom: 8px solid #f5f5f5;
}
.xin_c {
  color: #999;
}
.ciri {
  font-size: 10px;
  background: #c9e5f6;
  border-radius: 2px;
  border: 0.5px solid #87bcda;
  color: #309eda;
  padding: 1px 3px;
  margin-right: 5px;
  line-height: 10px;
  display: inline-block;
}
.mendiantu {
  width: 80px;
  height: 55px;
}
.dian_h1 {
  font-size: 15px;
  width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 14px;
}
.dian_h3 {
  color: #666;
  width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.van-goods-action-button--last {
  border-radius: 20px;
}
.dian_h2 {
  color: #666;
  line-height: 14px;
  margin-top: 8px;
}
.dian_ha {
  margin-left: 10px;
  width: 210px;
}
.xin_b {
  margin-left: 12px;
  font-size: 13px;
}
.xuan_a {
  font-size: 13px;
  overflow: hidden;
  //   line-height: 35px;
  margin: 5px 0;
}

.yixuan {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 10px;
}
.xq_h1_a {
  line-height: 12px;
  font-size: 15px;
  font-weight: bold;
}
.qianggou {
  display: inline-block;
  background: #f1dadc;
  color: #f23756;
  line-height: 14px;
  padding: 2px 3px;
  margin-left: 8px;
  border-radius: 2px;
  font-size: 13px;
}
.jian {
  display: inline-block;
  background: #fff;
  color: #f23756;
  // padding: 0 2px;
  width: 18px;
  text-align: center;
  border-radius: 2px;
}
.shi_b {
  line-height: 14px;
}
.jiage_b {
  line-height: 14px;
}
.xqbeij img {
  width: 100%;
}
.ql-editor {
  margin: 2px 15px;
  overflow: hidden;
   font-size: 14px;
  // margin-top: 15px;
}
</style>
<style lang="less">
.van-tabs__line {
  bottom: 20px;
}
.ql-editor p img {
 width: 100%;
   outline-width: 0px;
  vertical-align: top;
}
</style>
<style scoped>
.msgHtmlBox >>> img {
  width: 100%;
}
/* .msgHtmlBox >>> strong {
  font-weight: bold;
} */
</style>